import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import _ from 'lodash';
import VueSelect from 'vue-select'

let token = document.getElementsByName('csrf-token')[0].getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';

Vue.component('v-select', VueSelect)

document.addEventListener('DOMContentLoaded',loadVueFinder)
document.addEventListener('loadVueFinder',loadVueFinder)
function loadVueFinder() {
  if(document.getElementById("vue-finder")) {

    var data = JSON.parse(document.getElementById("vue-finder").dataset.vue)
    if(data.q1 == "") {
      data.q1 = null
    }
    if(data.q2 == "" || !data.q2) {
      data.q2 = []
    }

    console.log(data)

    new Vue({
      el: document.getElementById("vue-finder"),
      data: {
        q1: null,
        q2: [],
        help: null,
        ...data
      },
      methods: {
        toggleQ2(name) {
          if(this.q2.includes(name)) {
            this.q2 = _.without(this.q2,name)
          } else {
            this.q2.push(name)
          }
        },
        go() {

          // setup URL params
          var params = new URLSearchParams();
          params.append('q1', this.q1);
          this.q2.forEach((q) => {
            if(q.includes(this.q1)) {
              params.append('q2[]', q);
            }
          });

          // redirect to search page
          if(window.location.pathname.includes("investor")) {
            window.location.assign(`/investor/funds?${params.toString()}`);
          } else {
            window.location.assign(`/funds?${params.toString()}`);
          }



        },
        toggleHelp(name) {
          if(this.help == name) {
            this.help = null
          } else {
            this.help = name
          }
        }
      }
    })
  }
}